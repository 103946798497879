import React from 'react'


function Page(props) {

  if (typeof window !== 'undefined') {
    window.location.replace(`https://www.gulfstreampark.com/en-espanol#picks?expert=${props.slug}`)
  }

  return (
    <div>
      .
    </div>
  )
}

export default Page